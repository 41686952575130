import { type DomainConfigMap, DOMAINS } from '~/domains/core';
import ASBCSocialsJSON from '~/domains/core/infrastructure/data/social/asbc.json';
import AsbisSocialsJSON from '~/domains/core/infrastructure/data/social/asbis.json';
import { logoConfig } from '~/domains/core/infrastructure/data/logo/logoConfig.ts';

interface ReturnDomainConfig {
  config: DomainConfigMap;
}

export const domainConfig = (): ReturnDomainConfig => {
  const config: DomainConfigMap = {
    [DOMAINS.ASBC]: {
      logo: logoConfig[DOMAINS.ASBC],
      favicon: '/favicon-asbc.ico',
      loginFormTitle: 'components.loginForm.asbc.title',
      socials: ASBCSocialsJSON,
      footerCopyright: (year: number) =>
        `© ${year} ASBC LLC. All rights reserved.`,
    },
    [DOMAINS.BREEZY]: {
      logo: logoConfig[DOMAINS.BREEZY],
    },
    [DOMAINS.ASBIS]: {
      logo: logoConfig[DOMAINS.ASBIS],
      favicon: '/favicon-asbis.ico',
      loginFormTitle: 'components.loginForm.title',
      socials: AsbisSocialsJSON,
      footerCopyright: (year: number) =>
        `© 1995 - ${year}, ASBISc Enterprises Plc.`,
    },
  };

  return {
    config,
  };
};
