<template>
  <UiButton
    variant="text"
    size="small"
    color="white"
    class="px-sm-2 px-md-3"
    caret
    @click="router.push('/personal-settings')"
  >
    <template #prepend>
      <UiIcon icon="$account" />
    </template>
    <UiText variant="body-4" color="white"> {{ userName }} </UiText>
  </UiButton>
</template>

<script setup lang="ts">
import { UiButton, UiText, UiIcon } from '~/ui';
import { useRouter } from '~/infrastructure';

defineProps<Props>();

const router = useRouter();

export interface Props {
  userName: string;
}
</script>
