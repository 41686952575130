<template>
  <UiFooter>
    <template #logo>
      <AppLogo variant="light" />
    </template>
    <template #socials>
      <SocialList v-if="socials" :socials="socials" />
    </template>
    <template #copyright>
      {{ footerCopyright }}
    </template>
    <template v-if="isAuth" #links>
      <UiFooterLink v-for="(link, index) in links" :key="index" :src="link.src">
        <AppTranslate :identifier="link.text" />
      </UiFooterLink>
    </template>
  </UiFooter>
</template>
<script setup lang="ts">
import { UiFooter, UiFooterLink } from '~/ui';
import { AppLogo } from '~/app/layouts/logo';
import SocialList from '~/domains/social/components/SocialList/SocialList.vue';
import { type Link } from '~/app/types.d';
import { computed, type ComputedRef } from 'vue';
import useGetSocialViewModel from '~/domains/core/ui/viewModels/useGetSocialViewModel';
import { useAuthStore } from '~/domains/auth';
import { AppTranslate, useGetDataByDomain } from '~/infrastructure';
import { storeToRefs } from 'pinia';

const { isAuth } = storeToRefs(useAuthStore());
const { socials } = useGetSocialViewModel();
const { footerCopyright } = useGetDataByDomain();

const links: ComputedRef<Link[]> = computed(() => [
  {
    src: '/edifact',
    text: 'footer.menu.item.edifact.text',
  },
  {
    src: '/data-connectivity',
    text: 'footer.menu.item.api.text',
  },
  {
    src: '/xml-exchange',
    text: 'footer.menu.item.xml.text',
  },
  {
    src: '/service-centers',
    text: 'footer.menu.item.serviceCenters.text',
  },
]);
</script>
