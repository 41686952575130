<template>
  <VBtn
    :id="id"
    :variant="variant"
    :color="color"
    :size="size"
    :icon="icon"
    :rounded="rounded"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :height="height"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :loading="loading"
    :density="density"
    :to="to"
    :download="download"
    :href="href"
    class="button"
    @click="handleClick"
  >
    <template v-if="$slots['prepend']" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="!icon" #default>
      <slot />
    </template>
    <template v-if="$slots['append']" #append>
      <UiIcon v-if="caret" icon="$chevronDown" size="16" />
      <slot v-else name="append" />
    </template>
  </VBtn>
</template>

<script setup lang="ts">
import { VBtn } from 'vuetify/components/VBtn';
import { UiIcon } from '~/ui';
import { computed } from 'vue';
import { type Rounded } from '~/infrastructure';

export type Variant =
  | 'flat'
  | 'text'
  | 'elevated'
  | 'tonal'
  | 'outlined'
  | 'plain';

interface Props {
  color?: string;
  variant?: Variant;
  id?: string | null;
  size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
  icon?: string | null;
  caret?: boolean;
  rounded?: Rounded | boolean;
  maxWidth?: string | number | undefined;
  minWidth?: string | number | undefined;
  width?: string | number | undefined;
  height?: string | number | undefined;
  disabled?: boolean;
  prependIcon?: string;
  density?: 'default' | 'comfortable' | 'compact';
  to?: string;
  download?: boolean;
  loading?: boolean;
  href?: string;
}
const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  variant: 'flat',
  caret: false,
  rounded: 'xl',
  id: null,
  size: 'default',
  icon: null,
  maxWidth: undefined,
  minWidth: undefined,
  width: undefined,
  height: undefined,
  disabled: false,
  prependIcon: undefined,
  density: 'default',
  to: undefined,
  download: false,
  loading: false,
  href: undefined,
});

const emit = defineEmits<(e: 'click', value: PointerEvent) => void>();

function handleClick(event: PointerEvent): void {
  emit('click', event);
}

const height = computed(() => {
  if (props.height !== undefined) {
    return props.height;
  }

  if (props.size === 'large') {
    return '46px';
  }

  if (props.size === 'small') {
    return '30px';
  }

  return '40px';
});
</script>

<style scoped lang="scss">
.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 8px);
  height: v-bind(height);
}

.button.v-btn--size-large {
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.button.v-btn--size-default {
  font-size: 16px;
}
</style>
